.ant-layout {
  padding: 0;
  min-height: 500px;
  font-size: 15px;
  /*layout general*/
}

.ant-layout .ant-layout-footer {
  background-color: #000000;
  color: #fff;
}

.ant-layout .ant-layout-content {
  padding: 0 24px;
  min-height: 280px;
}

.ant-layout .ant-layout-header {
  background-color: #000000;
  color: #fff;
}

.ant-layout .main-container {
  padding: 25px;
}

.ant-layout .info-container {
  padding: 15px;
}

.ant-layout .icon-wrapper-slider {
  position: relative;
  padding: 0px 30px;
}

.ant-layout .icon-wrapper-slider .ant-slider {
  width: 80%;
  display: inline-block;
  margin: 0px 3%;
}

.ant-layout .icon-wrapper-slider .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
}

.card-exist-quest {
  background-color: white;
  background-position-y: center;
  background-image: url('../imgs/encuesta_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 0;
  height: 200px;
  overflow: hidden;
}

.card-exist-test {
  background-color: white;
  background-position-y: center;
  background-image: url('../imgs/evaluacion_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 0;
  height: 200px;
  overflow: hidden;
}

.card-exist-quest-new {
  background-color: white;
  background-position-y: top;
  background-image: url('../imgs/encuesta_nueva.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 0;
  height: 200px;
  overflow: hidden;
}

.card-exist-test-new {
  background-color: white;
  background-position-y: top;
  background-image: url('../imgs/evaluacion-nueva.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 0;
  height: 200px;
  overflow: hidden;
}

.menu-card-exist-quest {
  position: absolute;
  top: 5px;
  right: 5px;
}

.menu-card-exist-quest .ant-btn-default {
  border: none;
  box-shadow: none;
  display: inline;
}

.menu-card-exist-quest .ant-dropdown-trigger {
  border: none;
  box-shadow: none;
}

.card-quest-container {
  width: 150px;
  float: left;
  margin: 15px;
  height: 240px;
}

.card-quest-container .anticon-ellipsis {
  font-size: 25px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 400px;
  line-height: 160px;
  background: white;
  overflow: hidden;
}

.ant-carousel .slick-dots li button {
  background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #d5007f;
}

.ant-card-head {
  border-bottom: 2px solid #6d6565
}

.ant-divider {
  background: #c7c7c7;
}

.circle-with-data {
  border: solid 3px #d5007f;
  width: 160px;
  height: 160px;
  text-align: center;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.circle-with-data>div {
  font-size: 80px;
  font-weight: bold;
  color: #d5007f;
  line-height: 110px;
}

.global-menu-button {
  background: white;
  text-align: center;
  line-height: 45px;
  border-right: solid 1px #ccc;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all .15s ease;
  -moz-transition: all .15s ease;
  -ms-transition: all .15s ease;
  -o-transition: all .15s ease;
  transition: all .15s ease;
  border-bottom: 4px solid #f0f2f5;
}

.global-menu-button:hover {
  background: #f7e9f2;
  border-bottom: 4px solid #d5007f;
  -webkit-transition: all .15s ease;
  -moz-transition: all .15s ease;
  -ms-transition: all .15s ease;
  -o-transition: all .15s ease;
  transition: all .15s ease;
}

.global-menu-button.active {
  border-bottom: 4px solid #d5007f;
  font-weight: bold;
  cursor: default;
  background: white;
}

.global-menu-button.active:hover {
  border-bottom: 4px solid #d5007f;
  font-weight: bold;
}

.ant-checkbox-group-item {
  margin: 10px 8px 10px 0;
}

.vertical-divider-8em {
  height: 8em;
}

.vertical-divider-12em {
  height: 12em;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.custom-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.custom-filter-dropdown button {
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

.ant-table-wrapper {
  background-color: white;
}

td.align-right {
  text-align: right !important;
}

td.width-sm {
  width: 130px;
}

td.width-xs {
  width: 50px;
}

td.align-center {
  text-align: center !important;
}

.preview-modal .ant-modal-body {
  max-height: 700px;
  overflow: hidden auto;
}

.ant-back-top {
  right: 20px;
  bottom: 80px;
}

.ant-radio-group {
  width: 100%;
}

.ant-tabs-bar {
  margin: 0;
}

.ant-checkbox-wrapper {
  width: 100%;
}

.ant-notification-notice-icon {
  font-size: 30px;
}
.ant-card-body{
  padding: 0px 10px;
}